/* WatchesCatalog.css */
.brand-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .brand-square {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s;
  }
  
  .brand-square:hover {
    transform: scale(1.05);
  }
  
  .brand-logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .brand-square p {
    margin-top: 10px;
    font-size: 1.2em;
    color: #4e1e0b; /* Dark brown font color */
  }
  